/* eslint-disable @typescript-eslint/no-explicit-any */
import { Store, Vnode, Stream } from "bacta"
import { itemType } from "./types"

export type FormalAddress = {
	access: Array<{ lat: number; lng: number }>
	address: {
		city: string
		countryCode: string
		countryName: string
		district: string
		houseNumber: string
		label: string
		postalCode: string
		state: string
		stateCode: string
		street: string
	}
	distance: number
	houseNumberType: string
	id: string
	language: string
	mapView: {
		east: number
		north: number
		south: number
		west: number
	}
	position: {
		lat: number
		lng: number
	}
	resultType: string
	title: string
}

export const initialState = {
	initialized: false as boolean,

	category: null as string | null,
	item: {} as any,
	orders: [] as Array<itemType>,
	itemid: "" as string | null,
	info: {} as any,
	headerIcon: null as string | null,
	locationid: "" as string | null,
	items: [] as Array<itemType>,
	search: "",
	searchItems: "",

	user: {} as {
		user_id: string
		picture: string
		email: string
		name: string
		cards: Array<object>
		phone: string
		address: string
	},
	loading: false as boolean,
	userload: false as boolean,
	organization_id: 1,
	organization: null as any,
	contractor: null as any,
	profile_id: null as string | null,
	dateframe: null as Date | null,
	timeframe: "AM" as "AM" | "PM" as string,
	order: null as itemType | null,
	charging: "" as string,
	newAddress: false,
	newName: false,
	newPhone: false,
	newInstructions: false,
	newDescription: false,
	gettinglocation: false,
	charge: null as any,
	deliveryTo: "" as string,
	evlocation: "" as string,
	order_description: "" as string,
	order_instructions: "" as string,
	order_referral: "" as string,
	order_referrer: "" as string,
	order_contact_name: "" as string,
	order_phone: "" as string,
	order_email: "" as string,
	order_status: "" as string,
	order_id: "" as string,
	addressOptions: [] as Array<object>,
	checkoutindex: [] as Array<itemType>,
	state: "",
	outstanding: 0 as number,

	charger: 1 as number,
	separatedGarage: "" as string,
	concretePavers: "" as string,
	phase: "" as "1" | "3" as string,
	workingdistance: 0 as 5 | 15 | 25 | 25 as number,
	groundworkingdistance: 0 as number,
	additionals: 0 as number,
	storey: 0 as number,
	propertyowner: "" as string,
	aerial: "NO" as "YES" | "NO",

	drawOpen: false,
	ValidatedAddress: "" as string,
	FormalAddress: {} as FormalAddress,

	map: {} as any,
	AddressError: "",
	PaymentError: "",
	paySubmit: "",
	photoquestion: 0,
	uppy: null as null | any,
	files: [],
	terms: null as boolean | null,
	privacy: null as boolean | null,

	services: [] as Array<{
		category: string
		icon: string
		count: number
		service_id: string
		service_name: string
	}>,

	alwaysFalse: false as boolean,
}

export type State = typeof initialState

// Do not export `store` directly, use useStore() in child components
//
// Why:
//
// In bacta, we'll probably add a feature from React that mithril
// doesn't have called "Context"
//
// It lets a deeply nested child access some config/props/state
// from an ancestor component without manually threading all
// the props all the way down.
//
// We're sort of mocking that out right now until bacta has it
// but the fact it injects the dependency is useful when writing tests.
// If you directly import store here, it will make it harder to migrate later.
let store: Store<State>

export function useStore(v?: Vnode) {
	if (!store && v) {
		// global stream so the store lasts across route changes
		const state = (Stream as any)(initialState)
		store = v.useStore("@", state)
	}

	const s = store.prop("state")
	const d = store.prop("deliveryTo")
	const o = store.prop("order")
	const l = store.prop("loading")
	const u = store.prop("userload")
	const c = store.prop("checkoutindex")
	const user = store.prop("user")
	const addressOptions = store.prop("addressOptions")
	const charger = store.prop("charger")
	const separatedGarage = store.prop("separatedGarage")
	const concretePavers = store.prop("concretePavers")
	const phase = store.prop("phase")
	const workingdistance = store.prop("workingdistance")
	const groundworkingdistance = store.prop("groundworkingdistance")
	const additionals = store.prop("additionals")
	const storey = store.prop("storey")
	const propertyowner = store.prop("propertyowner")
	const aerial = store.prop("aerial")
	const dateframe = store.prop("dateframe")
	const categorySearch = store.prop("search")
	const itemSearch = store.prop("searchItems")
	const addressError = store.prop("AddressError")
	const ValidatedAddress = store.prop("ValidatedAddress")
	const FormalAddress = store.prop("FormalAddress")
	const timeframe = store.prop("timeframe")
	const evlocation = store.prop("evlocation")
	const order_instructions = store.prop("order_instructions")
	const order_referral = store.prop("order_referral")
	const order_referrer = store.prop("order_referrer")
	const order_contact_name = store.prop("order_contact_name")
	const order_phone = store.prop("order_phone")
	const order_email = store.prop("order_email")
	const order_status = store.prop("order_status")
	const photoquestion = store.prop("photoquestion")
	const files = store.prop("files")
	const uppy = store.prop("uppy")
	const privacy = store.prop("privacy")
	const terms = store.prop("terms")

	const returned = {
		store,
		s,
		d,
		o,
		l,
		u,
		c,
		dateframe,
		addressOptions,
		categorySearch,
		itemSearch,
		addressError,
		ValidatedAddress,
		FormalAddress,
		name,
		charger,
		separatedGarage,
		concretePavers,
		phase,
		workingdistance,
		groundworkingdistance,
		additionals,
		storey,
		propertyowner,
		aerial,
		timeframe,
		evlocation,
		order_instructions,
		order_referral,
		order_referrer,
		order_contact_name,
		order_phone,
		order_email,
		order_status,
		user,
		photoquestion,
		files,
		uppy,
		privacy,
		terms,
	}
	Object.assign(window, { completeStore: returned })
	return returned
}

export const restrictedarea = new Set([
	// 2000, 2006, 2007, 2008, 2009, 2010, 2011, 2015, 2016, 2017, 2018, 2019, 2020,
	// 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033,
	// 2034, 2035, 2036, 2037, 2038, 2039, 2040, 2041, 2042, 2043, 2044, 2045, 2046,
	// 2047, 2048, 2049, 2050, 2052, 2060, 2061, 2062, 2063, 2064, 2065, 2066, 2067,
	// 2068, 2069, 2070, 2071, 2072, 2073, 2074, 2075, 2076, 2077, 2079, 2080, 2081,
	// 2082, 2083, 2084, 2085, 2086, 2087, 2088, 2089, 2090, 2092, 2093, 2094, 2095,
	// 2096, 2097, 2099, 2100, 2101, 2102, 2103, 2104, 2105, 2106, 2107, 2108, 2109,
	// 2110, 2111, 2112, 2113, 2114, 2115, 2116, 2117, 2118, 2119, 2120, 2121, 2122,
	// 2123, 2125, 2126, 2127, 2128, 2129, 2130, 2131, 2132, 2133, 2134, 2135, 2136,
	// 2137, 2138, 2139, 2140, 2141, 2142, 2143, 2144, 2145, 2146, 2147, 2148, 2150,
	// 2151, 2152, 2153, 2154, 2155, 2156, 2157, 2158, 2159, 2160, 2161, 2162, 2163,
	// 2164, 2165, 2166, 2167, 2168, 2170, 2171, 2172, 2173, 2174, 2175, 2176, 2177,
	// 2178, 2179, 2190, 2191, 2192, 2193, 2194, 2195, 2196, 2197, 2198, 2199, 2200,
	// 2203, 2204, 2205, 2206, 2207, 2208, 2209, 2210, 2211, 2212, 2213, 2214, 2216,
	// 2217, 2218, 2219, 2220, 2221, 2222, 2223, 2224, 2225, 2226, 2227, 2228, 2229,
	// 2230, 2231, 2232, 2233, 2234, 2555, 2556, 2557, 2558, 2559, 2560, 2563, 2564,
	// 2565, 2566, 2567, 2568, 2569, 2570, 2571, 2572, 2573, 2574, 2745, 2747, 2748,
	// 2749, 2750, 2752, 2753, 2754, 2755, 2756, 2757, 2758, 2759, 2760, 2761, 2762,
	// 2763, 2765, 2766, 2767, 2768, 2769, 2770, 2773, 2774, 2777,

	2000,
	2006, 2007, 2008, 2009, 2010, 2011, 2015, 2016, 2017, 2018, 2019, 2020, 2021,
	2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2031, 2032, 2033, 2034, 2035,
	2036, 2037, 2038, 2039, 2040, 2041, 2042, 2043, 2044, 2045, 2046, 2047, 2048,
	2049, 2050, 2130, 2131, 2132, 2133, 2134, 2135, 2136, 2190, 2191, 2192, 2193,
	2194, 2195, 2196, 2203, 2204, 2205, 2206, 2207, 2208, 2209, 2210, 2211, 2212,
	2213, 2216, 2217, 2218, 2219, 2220, 2221, 2222, 2223, 2224, 2225, 2226, 2227,
	2228, 2229, 2230, 2231, 2232, 2233, 2234, 2000, 2029, 2030, 1485, 1825, 2006,
	2007, 2008, 2015, 2016, 2017, 2018, 2019, 2020, 2032, 2033, 2037, 2038, 2039,
	2040, 2042, 2043, 2044, 2045, 2046, 2048, 2049, 2050, 2127, 2130, 2131, 2132,
	2133, 2134, 2135, 2136, 2137, 2138, 2141, 2143, 2162, 2190, 2191, 2192, 2193,
	2194, 2195, 2196, 2197, 2198, 2199, 2200, 2203, 2204, 2205, 2206, 2207, 2208,
	2209, 2210, 2211, 2212, 2213, 2214, 2216, 2217, 2218, 2219, 2220, 2221, 2222,
	2223, 2224, 2225, 2226, 2227, 2228, 2229, 2230,
])
